
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ContainerType from "@/models/ContainerType.model";

@Component
export default class ConfirmDeleteContainerComponent extends Vue {
  @Prop({required: true})
  private deleteContainer!: ContainerType;

  @Prop({required: false, default: false})
  private loading!: boolean;

  /**
   * closes the modal
   */
  private dismiss(deleted: boolean = false) {
    this.$emit('dismiss', deleted);
  }

  /**
   * is called when the delete button is clicked, closes modal and tells the info feed view to delete the item
   * @private
   */
  private onDeletePressed(): void {
    this.dismiss(true);
  }
}
